import React, { ComponentPropsWithoutRef, forwardRef } from 'react';
import { clsx } from 'clsx';
import CloudinaryImage from '../../atoms/CloudinaryImage/CloudinaryImage';
import {
    SQUARE_SMALL,
    SQUARE,
    SQUARE_BIG,
} from '../../atoms/CloudinaryImage/constants';
import { BackgroundCheckedIcon } from '../../atoms/icons';

export type AvatarCommonProps = ComponentPropsWithoutRef<'div'> & {
    hasBorder?: boolean;
    size?: keyof typeof AvatarSizes;
    placeholderUrl?: string;
    isVerified?: boolean;
    onLoadImage?(props: React.SyntheticEvent<HTMLImageElement, Event>): void;
};

export type AvatarPropsWithSrc = AvatarCommonProps & {
    src: string;
    publicId?: never;
    cloudinaryEnv?: never;
};

// FIXME: Temp solution while we still have cloudinary stuff on DS
export type AvatarPropsWithPublicId = AvatarCommonProps & {
    src?: never;
    publicId: string;
    cloudinaryEnv?: {
        hostName: string;
        cloudName: string;
    };
};

export type AvatarProps = AvatarPropsWithSrc | AvatarPropsWithPublicId;

export const AvatarSizes = {
    '2xs': {
        transformation: SQUARE_SMALL,
        className: 'h-[24px] w-[24px]',
    },
    xs: {
        transformation: SQUARE_SMALL,
        className: 'h-[44px] w-[44px]',
    },
    sm: {
        transformation: SQUARE_SMALL,
        className: 'h-[64px] w-[64px]',
    },
    md: {
        transformation: SQUARE_SMALL,
        className: 'h-[76px] w-[76px]',
    },
    lg: {
        transformation: SQUARE_SMALL,
        className: 'h-[96px] w-[96px]',
    },
    xl: {
        transformation: SQUARE,
        className: 'h-[144px] w-[144px]',
    },
    '2xl': {
        transformation: SQUARE_BIG,
        className: 'h-[160px] w-[160px]',
    },
};

const defaultStyle = 'rounded-full overflow-hidden';

const Avatar = forwardRef<HTMLDivElement, AvatarProps>(
    (
        {
            publicId,
            src,
            size = 'sm',
            hasBorder = false,
            placeholderUrl,
            isVerified,
            cloudinaryEnv,
            onLoadImage,
            ...rest
        },
        ref
    ) => {
        const { transformation, className: avatarClassName } =
            AvatarSizes[size];
        const classNames = clsx(defaultStyle, avatarClassName, {
            'border-[3px] border-solid border-utility-white': hasBorder,
            'h-[96px] w-[96px] lg:h-[144px] lg:w-[144px]': size === 'md',
        });
        const verifiedWrapperClassNames = clsx(
            'absolute -bottom-[10px] z-10 rounded-full border border-solid border-grey-100 bg-utility-white p-[7px] text-primary-500 lg:border-none ',
            {
                'lg:left-0': size === 'md',
                '-bottom-1 -left-3': size === 'lg',
            }
        );
        const imageCommonProps = {
            alt: 'Avatar',
            className: classNames,
            onLoad: onLoadImage,
        };

        return (
            <div ref={ref} className="relative" {...rest}>
                {typeof src === 'string' ? (
                    <img {...imageCommonProps} src={src} />
                ) : (
                    <CloudinaryImage
                        {...imageCommonProps}
                        publicId={publicId}
                        transformation={transformation}
                        placeholderUrl={placeholderUrl}
                        cloudinaryEnv={cloudinaryEnv}
                    />
                )}

                {isVerified ? (
                    <div className={verifiedWrapperClassNames}>
                        <BackgroundCheckedIcon className="h-6 w-6 lg:h-8 lg:w-8" />
                    </div>
                ) : null}
            </div>
        );
    }
);

export default Avatar;
